<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">Domains</router-link>
                </p>
                </v-col>
            </v-row> -->
        <v-row justify="center" class="py-5 px-10 mt-8" v-if="isViewReady">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <h1 class="text-h6 font-weight-light text-center">{{ domain.name }}</h1>
            <!-- <h1 class="text-caption font-weight-light text-center">{{ domain.status }}</h1> -->
            </v-col>
        </v-row>
        <v-row justify="center" class="py-5 px-10" v-if="isViewReady">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-overline mb-0">Branding</p>
                <p class="mb-0 pb-0">
                    Nameserver branding makes your nameservers appear to the public as,
                    for example, ns1.{{ domain.name }} instead of
                    ns1.libertydns.io. <!-- TODO: need to use domain name from service configuration, instead of hard-coded -->
                </p>
                <p class="mb-0 pb-0">
                    <v-checkbox v-model="isBranded" label="Enable nameserver branding"></v-checkbox>
                </p>

                <p class="text-overline mb-0 mt-8">Directions</p>
                <p class="mb-0 pb-0">
                    To host your domain's DNS at LibertyDNS, login to your registrar and set the following DNS servers for your domain:
                </p>

                <v-list dense class="mt-8">
                    <v-list-item v-for="(item, idx) in nameserverList" :key="idx">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.hostname }}.{{ item.domain }}
                            <v-btn icon small @click="copyToClipboard(item)">
                                <font-awesome-icon :icon="['far', 'copy']" class="grey--text"/>
                            </v-btn>
                            </v-list-item-title>
                        </v-list-item-content>
                        <!-- <v-list-item-action>
                        </v-list-item-action> -->
                    </v-list-item>
                </v-list>
                <v-text-field :value="clipboard" readonly outlined ref="clipboardInput" class="clipboard" v-show="!!clipboard"></v-text-field>
                <!-- <v-textarea :value="clipboard" readonly outlined ref="clipboardInput" class="clipboard"></v-textarea> -->
                <p class="mb-0 pb-0 mt-8">
                    GoDaddy: My Domains -&gt; DNS Management -&gt; Enter my own nameservers (advanced) (<TextLink :href="godaddyEditNameserversLink" target="_blank">direct link</TextLink>)
                </p>

                <template v-if="domain.nameserver_branding">
                <p class="mb-0 pb-0 mt-8">
                    These nameserver hostnames are branded, which requires an additional configuration step at your registrar. You need to inform your registrar of the IP addresses of these nameservers.
                </p>
                <v-simple-table class="mt-8">
                    <template #default>
                        <thead>
                            <tr>
                                <th>Nameserver</th>
                                <th>IP address</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, idx) in nameserverList" :key="idx">
                                <td><span class="font-weight-bold">{{ item.hostname }}</span>.{{ item.domain }}
                                    <v-btn icon small @click="copyToClipboard(`${item.hostname}.${item.domain}`)">
                                        <font-awesome-icon :icon="['far', 'copy']" class="grey--text"/>
                                    </v-btn>
                                </td>
                                <td>
                                    <p v-for="(ipaddr, idx2) in item.ipaddr_list" :key="idx2">
                                    {{ ipaddr }}
                                    <v-btn icon small @click="copyToClipboard(ipaddr)">
                                        <font-awesome-icon :icon="['far', 'copy']" class="grey--text"/>
                                    </v-btn>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <p class="mb-0 pb-0 mt-8">
                    GoDaddy: My Domains -&gt; Domain Settings -&gt; Host names (<TextLink :href="godaddyEditNameserverAddressLink" target="_blank">direct link</TextLink>)
                </p>
                </template>

                <p class="mb-0 pb-0 mt-8">
                    <router-link :to="{ name: 'account-view-domain', params: { accountId: this.$route.params.accountId, domain: this.domain.name }}">Return to {{ domain.name }}</router-link>
                </p>
            </v-col>
        </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>
</style>

<script>
import { mapState } from 'vuex';
import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        TextLink,
    },
    data: () => ({
        account: null,
        domain: null,
        error: null,
        status: null,
        // TODO: load the nameserver list from the server, so the server will return either the default list or the branded list (and also the list might be different for each account, if they are hosted on different clusters)
        // TODO: for branded domains, `ns1.${domain.name}`, `ns2.${domain.name}`, etc.
        nameserverList: [
            {
                hostname: 'ns1',
                domain: 'libertydns.io',
                // ipaddr_list: ['67.205.163.143'], // this is ns1.libertycloud.io
                ipaddr_list: ['137.184.80.14'], // TODO: need to get this from server API instead of hard-coded
            },
            {
                hostname: 'ns2',
                domain: 'libertydns.io',
                // ipaddr_list: ['67.205.136.196'], // this is ns2.libertycloud.io
                ipaddr_list: ['68.183.119.45'], // TODO: need to get this from server API instead of hard-coded
            },
        ],
        clipboard: null,
        isBranded: false, // TODO: load this from domain info record in database, and also let user enable/disable;  branded nameservers means "ns1.example.com" and "ns2.example.com" instead of "ns1.libertydns.io" and "ns2.libertydns.io" , so the nameserver hostnames match the domain being managed
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        accountName() {
            return this.isViewReady ? this.account?.name ?? 'Unknown' : '';
        },
        isViewReady() {
            return this.account !== null && this.domain !== null;
        },
        godaddyEditNameserverAddressLink() {
            // TODO: this is brittle, we need to monitor godaddy for changes if we're going to show this, or have a way for users to let us know it is broken
            return this.domain ? `https://dcc.godaddy.com/manage/${this.domain.name}/dns/hosts` : '';
        },
        godaddyEditNameserversLink() {
            // TODO: this is brittle, we need to monitor godaddy for changes if we're going to show this, or have a way for users to let us know it is broken
            return this.domain ? `https://dns.godaddy.com/${this.domain.name}/nameservers` : '';
        },
    },
    watch: {
        isBranded(newValue) {
            this.editIsBranded(newValue);
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).self.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadDomain() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadDomain: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.get({ name: this.$route.params.domain });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.domain = response;
                    this.isBranded = response.nameserver_branding ?? false;
                } else {
                    // TODO: redirect back to domain list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load domain', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadDomain: false });
            }
        },
        copyToClipboard(value) {
            this.clipboard = value;
            this.$nextTick(() => {
                const input = this.$refs.clipboardInput.$el.querySelector('input');
                // const input = this.$refs.clipboardInput.$el.querySelector('textarea');
                input.focus();
                input.select();
                input.setSelectionRange(0, input.value.length);
                document.execCommand('copy');
                this.$bus.$emit('snackbar', { type: 'success', headline: 'Copied to clipboard', message: value });
                this.clipboard = null;
            });
        },
        async editIsBranded(newValue) {
            console.log(`editIsBranded ${newValue}`);
            try {
                this.error = false;
                this.$store.commit('loading', { loadDomain: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.edit({ name: this.$route.params.domain }, { nameserver_branding: newValue });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.loadDomain();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to change domain setting' });
                }
            } catch (err) {
                console.error('failed to load domain', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to change domain setting' });
            } finally {
                this.$store.commit('loading', { loadDomain: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadDomain();
    },
};
</script>
